import React from "react"

import Card from "../components/card"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Services = ({data}) => {
  return (
    <Layout>
      <div
        className="content-wrapper"
        style={{
          borderLeft: `1px solid rgba(0,0,0,0.08)`,
          background: `white`,
        }}
      >
        <SEO title="Services" />
        <h1 style={{}}>Services</h1>
        <Card images={data.allFile.edges} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/seo/" }}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1000, quality: 85, traceSVG: { color: "#2B2B2F" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default Services
