import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Card = ({ services, images }) => {
  const cardValues = {
    services: [
      {
        id: 1,
        title: "Technical SEO Audit",
        image: images[0].node.childImageSharp.fluid,
        description:
          "Full SEO audit, highlighting your onsite issues and your missed opportunities. Starting from £200.",
        moreDetail:
          "You'll receive a PDF document explaining everything that's holding your performance back, as well as how to fix it. Consultation call and implementation services also available.",
      },
      {
        id: 2,
        title: "Content Optimization",
        image: images[1].node.childImageSharp.fluid,
        description:
          "Content recommendations that will maximise your ranking potential across your target keywords. Starting from £100.",
        moreDetail:
          " You'll receive a Word review of your key content with suggested changes to boost your SEO. Content gap analysis, copywriting and implementaion services also available.",
      },

      {
        id: 3,
        title: "PR & Outreach Strategies",
        image: images[2].node.childImageSharp.fluid,
        description:
          "Digital PR and link building campaigns, aiming to strenghten your brand and authority online. Starting from £500.",
        moreDetail:
          "Custom creative ideas depending on what suits your industry and goals. Previous projects include infographic creation, influencers/bloggers gifting or events.",
      },
      {
        id: 4,
        title: "Migration Support",
        image: images[3].node.childImageSharp.fluid,
        description:
          "Migration checklist, prelaunch & postlaunch website testing, organic visibility benchmarking. Starting from £500.",
        moreDetail:
          "Protect your organic performance during a migration by minimizing fluctuations in the short run and getting what's needed to go higher than ever long term.",
      },
      {
        id: 5,
        title: "Reputation Management",
        image: images[4].node.childImageSharp.fluid,
        description:
          "Fake news hurting your business? Take control of your online reputation. Starting from £500.",
        moreDetail:
          "Outrank negative search results and bring users and on your side with a positive story instead.",
      },
      {
        id: 6,
        title: "Taxonomy Reviews",
        image: images[5].node.childImageSharp.fluid,
        description:
          "Taxonomy review to improve your website structure for both users and crawlers. Starting from £300.",
        moreDetail:
          "Are your webpages competing against each other? Can users navigate your website easily to conversion? Does your web structure meet users demand? You'll receive an Excel document answering all these questions, as well as solutions.",
      },
      {
        id: 7,
        title: "International SEO Strategies",
        image: images[6].node.childImageSharp.fluid,
        description:
          "Strategy to help you expand your business internationally or solve your cannibalization issues between your regional markets. Starting from £250. ",
        moreDetail:
          "Main website ranks in other markets, hurting your CTR? Time to expland globally, but it's unclear where to start? You'll receive a strategy based on your best options.",
      },
      {
        id: 8,
        title: "Local SEO Strategies",
        image: images[7].node.childImageSharp.fluid,
        description:
          "Strategy to help you increase your local visibility and get more enquiries. Starting from £250.",
        moreDetail:
          "Make your business known within your local community. You will receive an all-round strategy based on the local signals most suitable for your industry/region. ",
      },
      {
        id: 9,
        title: "Rolling SEO Campaigns",
        image: images[8].node.childImageSharp.fluid,
        description:
          "Up your organic game with a monthly SEO campaign. Starting from £500/month.",
        moreDetail:
          "Monthly SEO campaigns are 100% tailored to your needs. Includes: full technical audit, keyword research, regular calls, implementation if required and monthly reporting.",
      },
      {
        id: 10,
        title: "Site Speed Audits",
        image: images[9].node.childImageSharp.fluid,
        description:
          "A slow website is the underlying cause to all your issues: poor rankings, high bounce-rate, low conversions. But what's causing the high loading times? Starting from £250.",
        moreDetail:
          "You will receive an audit highlighting all web elements slowing down your website and how to fix that. ",
      },
      {
        id: 11,
        title: "Schema Mark-up Guidelines",
        image: images[10].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 12,
        title: "Conversion Rate Optimization",
        image: images[11].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 13,
        title: "Analytics Configuration",
        image: images[12].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 14,
        title: "Performance Review",
        image: images[13].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 15,
        title: "Traffic Drop Analysis",
        image: images[14].node.childImageSharp.fluid,
        description:
          "This Traffic Drop Analysis will help you identify your main impacted areas and what's caused the decrease in the first place. Starting from £300.",
        moreDetail:
          "You'll receive a full analysis helping you identify the factors that lead to the loss in traffic, the main affected sections and a strategy outline on how to tackle the problem.",
      },
      {
        id: 16,
        title: "Penalty Removal",
        image: images[15].node.childImageSharp.fluid,
        description:
          "Guilty as charged? Let's find your website's way back into Google's grace with a solid white-hat SEO strategy. Starting from £700.",
        moreDetail:
          "Wether is Penguin, Panda, a manual penalty or simply a hit by an algorythm update, I can help you get back on track with a series of SEO initiatives tactically planned.  ",
      },
      {
        id: 17,
        title: "Keyword Research",
        image: images[16].node.childImageSharp.fluid,
        description:
          "Keyword research helps you identify how people search for the services you offer and what terms they use. Starting from £100.",
        moreDetail:
          "You will receive an Excel document highlighting your priority keywords. Content optimization and content gap analysis available as well.",
      },
      {
        id: 18,
        title: "Backlink Audits",
        image: images[17].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 19,
        title: "Content Gap Analysis",
        image: images[18].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      {
        id: 20,
        title: "Competitor Research",
        image: images[19].node.childImageSharp.fluid,
        description:
          "When we build a content marketing strategy, we utilise the latest emerging digital trends and technology from across the industry",
        moreDetail:
          "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      },
      // {
      //   id: 21,
      //   title: "Pay Per Click Ads",
      //   image: images[20].node.childImageSharp.fluid,
      //   description:
      //     "PPC and SEO go hand-in-hand. Create smart campaigns, where your paid activity complements your organic performance. Starting from 250.",
      //   moreDetail:
      //     "Digital trends and technology from across the industry Digital trends and technology from across the industry, stuff and Price 200€",
      // },
    ],
  }

  return (
    <div className="flex-wrap">
      {cardValues.services &&
        cardValues.services.map(service => {
          return (
            <div key={service.id} className="InfoCard__InfoCardWrapper">
              <div className="InfoCard__Details">
                <div className="InfoCard__Details-header">
                  <div className="InfoCard__ServiceIcon">
                    <Img fluid={service.image} width="100%" />
                  </div>
                  <h3>{service.title}</h3>
                </div>
                <div className="InfoCard__Details-text">
                  <p> {service.description}</p>
                  <p className="InfoCard__more-details">{service.moreDetail}</p>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

Card.propTypes = {
  siteTitle: PropTypes.string,
}

export default Card
